import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import "../assests/css/toggleSidebar.css";
import axios from "axios";
import io from "socket.io-client";
import jwt_decode from "jwt-decode";
import yogaImage from "../assests/images/yoga_img.jpg";
import swal from "sweetalert";
function VideoPlayer() {
  const logCheck = localStorage.getItem("data");
  const [counter, setCounter] = useState(5);
  const decoded_data = jwt_decode(logCheck);
  const [cameraStream, setCameraStream] = useState(null);
  const webcamRef = useRef(null);
  const navigate = useNavigate();
  const playerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isStarted, setIsStarted] = useState(localStorage.getItem("isStarted"));
  const [isCompleted, setIsCompleted] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [videoUrl, setVideoUrl] = useState(null);
  const [selectedOption, setSelectedOption] = useState("video1");
  const [isRecording, setIsRecording] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const video1 = "https://d34t46d6qns3cr.cloudfront.net/english.mp4";
  const video2 = "https://d34t46d6qns3cr.cloudfront.net/hindi- video.mp4";
  const videoContainerRef = useRef(null);
  const videoRef = useRef(null);
  const socket = useRef(null);
  const intervalId = useRef(null);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(
    localStorage.getItem("watchedTime")
  );
  const [remainingTime, setRemainingTime] = useState(
    localStorage.getItem("remainingTime")
  );
  const distanceIntervalRef = useRef(null);
  const movementIntervalRef = useRef(null);
  const blinkIntervalRef = useRef(null);

  const uniqueVideoUrlRef = useRef("");

  useEffect(() => {
    socket.current = io("wss://streaming.tratakkriyapractice.co.in/");

    socket.current.on("connection_successful", (data) => {
      console.log(data.data);
      const token = data.token;
      console.log(token);

      const uniqueVideoUrl = `https://streaming.tratakkriyapractice.co.in/video/${token}`;
      uniqueVideoUrlRef.current = uniqueVideoUrl;
    });

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, []);
  const captureFrame = () => {
    const canvas = document.createElement("canvas");
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

    canvas.toBlob((blob) => {
      socket.current.emit("video_frame", blob);
    }, "image/jpeg");
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        window.location.reload();
        // console.log("ye button maine dabaya");
        stopRecording();
        localStorage.setItem("watchedTime", currentTime);
        handleEscapePress();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [cameraStream]);

  const startStream = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    videoRef.current.srcObject = stream;
    videoRef.current.play();
    setIsRecording(true);
    intervalId.current = setInterval(() => {
      captureFrame();
    }, 41.666);
  };

  const stopRecording = () => {
    setIsRecording(false);
    clearInterval(intervalId.current);
    videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
  };

  const handleEscapePress = () => {
    setIsPaused(true);
    setIsPlaying(false);
    setIsRecording(false);
    // Exit fullscreen
    if (document.fullscreenElement && cameraStream) {
      cameraStream.getTracks().forEach((track) => track.stop());
      setCameraStream(null);
      document.exitFullscreen();
    }

    // Stop the camera
    // if (cameraStream) {
    //   cameraStream.getTracks().forEach((track) => track.stop());
    //   setCameraStream(null);
    // }

    // Navigate back to the dashboard
    navigate("/dashboard_user");
  };

  let stopProcessing = false;

  const requestCameraPermission = async () => {
    try {
      // console.log("Camera access granted");

      const videoUrl = {
        URL: uniqueVideoUrlRef.current,
        USER: decoded_data.user.uid.replace(/\//g, "_"),
      };
      console.log(videoUrl);

      const makeRequest = async (url, label) => {
        try {
          const result = await axios.post(url, videoUrl);
          console.log(`${label} Response:`, result.data);
        } catch (error) {
          console.error(`Error in ${label} analysis:`, error);
        }
      };

      const processRequests = async () => {
        while (!stopProcessing) {
          if (duration > currentTime || duration === 0) {
            await makeRequest(
              "https://tratakml.tratakkriyapractice.co.in/analyze/distance",
              "Distance"
            );
            await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for 3 seconds

            await makeRequest(
              "https://tratakml.tratakkriyapractice.co.in/analyze/movement",
              "Movement"
            );
            await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for 3 seconds

            await makeRequest(
              "https://tratakml.tratakkriyapractice.co.in/analyze/blink",
              "Blink"
            );
            await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for 3 seconds
          } else {
            break;
          }
        }
      };

      // Add event listener for ESC key press
      const handleKeyPress = (event) => {
        if (event.key === "Escape") {
          stopProcessing = true;
          // console.log("Stopping API calls");
        }
      };

      document.addEventListener("keydown", handleKeyPress);

      // Start processing requests
      await processRequests();

      // Clean up event listener
      document.removeEventListener("keydown", handleKeyPress);
    } catch (error) {
      console.error("Camera access denied:", error);
    }
  };
  // useEffect(() => {
  //   if (!isFullscreen) {
  //     clearInterval(distanceIntervalRef.current);
  //     clearInterval(movementIntervalRef.current);
  //     clearInterval(blinkIntervalRef.current);
  //   }
  // }, [isFullscreen]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleFullscreenChange = () => {
    if (!document.fullscreenElement) {
      setIsPlaying(false);
    }
  };

  const handleProgressChange = (current) => {
    setCurrentTime(current.playedSeconds);
    setRemainingTime(duration - current.playedSeconds);
  };

  const handleSeek = () => {
    if (currentTime === duration) {
      setIsCompleted(true);
      setCurrentTime(0);
      localStorage.setItem("watchedTime", 0);
    }
    playerRef.current.seekTo(currentTime);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const togglePlayPause = () => {
    if (isPaused) {
      setIsPaused(false);
    } else {
      setIsPaused(true);
    }
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const handlePlayClick = () => {
    requestCameraPermission();

    if (selectedOption === "video1") {
      setVideoUrl(video1);
      const videoContainer = document.querySelector(".video-player");
      if (videoContainer) {
        videoContainer.requestFullscreen().then(() => {
          setIsPlaying(true);

          setIsPaused(false);
        });
      }
      console.log("Playing Video 1");
    } else if (selectedOption === "video2") {
      setVideoUrl(video2);
      const videoContainer = document.querySelector(".video-player");
      if (videoContainer) {
        videoContainer.requestFullscreen().then(() => {
          setIsPlaying(true);
          setIsPaused(false);
          setIsStarted(true);
        });
      }
      console.log("Playing Video 2");
    }
    startStream();
  };

  const buttonDabao = async () => {
    setIsLoading(true);
    startStream();

    // const videoUrl = {
    //   URL: uniqueVideoUrlRef.current,
    //   USER: decoded_data.user.uid.replace(/\//g, "_"),
    // };

    const result = await axios.post(
      "https://tratakml.tratakkriyapractice.co.in/eyeGlass",
      videoUrl
    );

    console.log(result.data);
    if (result.data === 906) {
      setIsModalOpen(true);
      setIsLoading(false);
      // if (setIsModalOpen === true) {
      //   handlePlayClick();
      // }
    } else if (result.data === 905) {
      setIsLoading(false);
      swal({
        title: "Remove Your EyeGlass",
        text: "Please remove your eyeglasses for better results.",
        icon: "warning",
      });
    } else if (result.data === 907) {
      setIsModalOpen(true);
      setIsLoading(false);
    }
  };

  const voice_Guide = () => {
    handlePlayClick();
  };

  const handleAgreeClick = () => {
    if (isAgreed) {
      setIsStarted(true);
      localStorage.setItem("isStarted", true);
      setIsModalOpen(false);
      voice_Guide();
    } else {
      alert("Please agree to the instructions before proceeding.");
    }
  };
  const handleContinue = async () => {
    setIsLoading(true); // Set loading state to true
    startStream();
    const videoUrl = {
      URL: uniqueVideoUrlRef.current,
      USER: decoded_data.user.uid.replace(/\//g, "_"),
    };

    try {
      const result = await axios.post(
        "https://tratakml.tratakkriyapractice.co.in/eyeGlass",
        videoUrl
      );

      console.log(result.data);
      if (result.data === 906) {
        setIsPlaying(true);
        videoContainerRef.current.requestFullscreen();
        setIsPaused(false);
        handlePlayClick();
      } else if (result.data === 905) {
        setIsLoading(false);
        swal({
          title: "Remove Your EyeGlass",
          text: "Please remove your eyeglasses for better results.",
          icon: "warning",
        });
      } else if (result.data === 907) {
        setIsPlaying(true);
        videoContainerRef.current.requestFullscreen();
        setIsPaused(false);
        handlePlayClick();
        // swal({
        //   title:
        //     "Your Face is Not Detected Please Ensure Proper Lighting and Try Again ",
        //   text: "",
        //   icon: "warning",
        // });
      }
    } catch (error) {
      console.error("API call failed", error);
      swal({
        title: "Network Error",
        text: "There was an error communicating with the server. Please try again.",
        icon: "error",
      });
    } finally {
      setIsLoading(false); // Set loading state to false
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (document.fullscreenElement) {
        setIsFullscreen(true);
      } else {
        setIsFullscreen(false);
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const handleEnded = () => {
    swal({
      title: "Video Ended",
      text: "Thank you for watching the video, press 'esc' button for exit ",
    });
    console.log("Video has ended");
    setIsPlaying(false);
    setIsFullscreen(false);
    setIsPaused(true);
    setIsCompleted(true);
    setIsStarted(false);
    setCameraStream(null);
    document.exitFullscreen();
    handleEscapePress();
    localStorage.setItem("isCompleted", true);
  };

  return (
    <div className="content">
      <div className="row"></div>
      <div className={`profile-page 'sidebar-open' : ''}`}>
        <div className="profile-content">
          <div className="profile-details"></div>
        </div>
      </div>

      <div className="video-container" ref={videoContainerRef}>
        <div className="video-player">
          {isFullscreen && (
            <div className="fullscreen-toggle">
              <button className="pause-btn" onClick={togglePlayPause}>
                {!isPaused ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    height="45px"
                    width="45px"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14.25 9v6m-4.5 0V9M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="45px"
                    viewBox="0 -960 960 960"
                    width="45px"
                    fill="#e8eaed"
                  >
                    <path d="m380-300 280-180-280-180v360ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                  </svg>
                )}
              </button>
            </div>
          )}
          {isPlaying && (
            <ReactPlayer
              className="Video"
              ref={playerRef}
              url={videoUrl}
              playing={!isPaused}
              width="100%"
              height="100%"
              onStart={handleSeek}
              onPause={() => {
                localStorage.setItem("watchedTime", currentTime);
                localStorage.setItem("remainingTime", remainingTime);
              }}
              onProgress={handleProgressChange}
              onDuration={(duration) => setDuration(duration)}
              onEnded={handleEnded}
            />
          )}
        </div>
        {!isStarted ? (
          <div className="tratak-btn">
            <div>
              {isLoading && (
                <div className="loading-screen">Please Wait...</div>
              )}
              <button onClick={buttonDabao}>Start Tratak Kriya</button>
            </div>
          </div>
        ) : (
          <div className="tatak-time">
            <h6>Watched Time : {formatTime(currentTime)}</h6>
            <h6>Remaining Time : {formatTime(remainingTime)}</h6>
            <div>
              {isLoading && (
                <div className="loading-screen">Please Wait...</div>
              )}
              <button className="continue-btn " onClick={handleContinue}>
                Continue Tratak Kriya
              </button>
            </div>
          </div>
        )}
      </div>

      <div>
        <video
          ref={videoRef}
          autoPlay
          playsInline
          style={{ display: "none" }}
        />
      </div>
      {isModalOpen && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-content">
            <span className="close" onClick={() => setIsModalOpen(false)}>
              &times;
            </span>
            <h2>Instructions</h2>
            <p>
              Please read the following instructions carefully before starting
              the Tratak Kriya video:
            </p>
            <ul>
              <b>
                <li>Remove your glasses during practice.</li>
              </b>
              <b>
                <li>Keep your back and neck in a straight line.</li>
              </b>
              <b>
                <li>Keep the screen 30-45 cm away from you.</li>
              </b>
              <b>
                <li>Practice for the entire duration to improve eye health.</li>
              </b>
              <b>
                <li>Press 'esc' button for stop Tratak Kriya </li>
              </b>
            </ul>
            <div className="language-select">
              <h6>
                <b>Select a Language</b> for Tratak Kriya
              </h6>
              <select value={selectedOption} onChange={handleOptionChange}>
                <option value="video1">English</option>
                <option value="video2">Hindi</option>
              </select>
            </div>
            <div>
              <input
                type="checkbox"
                checked={isAgreed}
                onChange={() => setIsAgreed(!isAgreed)}
              />
              <span> I agree to the instructions </span>
            </div>
            <button className="modal-button" onClick={handleAgreeClick}>
              Yes, Agree & Continue
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoPlayer;
